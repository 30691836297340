.wrapper {
  width: 100vw;
  max-width: 500px;
  border-radius: 12px;
  background: #FFF;
  height: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
}

.text {
  white-space: pre-line;
}

.close_btn {
  border-radius: 90px;
  background: var(--Primary-Colors-Yellow-400, #FFD200);
  height: 50px;
}