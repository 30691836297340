.dropdown {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    & svg {
      transition: all 0.3s ease-in-out;
    }
  }
  &__description {
    white-space: pre-wrap;
    margin-top: 10px;
    color: #000 !important;
  }
  &__content {
    max-height: 0;
    overflow: hidden;
  }
  &.active &__content {
    max-height: 100000px;
    transition: max-height 3s ease-in-out;
  }
  &.disabled &__header {
    cursor: unset;
  }
  &.active &__header svg {
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
  }
}
