.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  align-items: flex-start;
}

.badge {
  padding: 4px 12px;
  border-radius: 6px;
  background: var(--Green-200, #5AC790);
  color: #FFF;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 8px;
}

.message {
  list-style: none;
  margin-top: 10px;
}

.fpg {
  margin-top: 10px;
}