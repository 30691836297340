.wrapper {}

.messages {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.confirm_link {
  display: inline-block;
  border-radius: var(--M-radius, 6px);
  background: var(--Neutral-Colors-White, #FFF);
  color: var(--Neutral-Colors-Black-400, #000);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  padding: 5px 12px;
  margin-top: 8px;
}

.message {
  list-style: none;
}