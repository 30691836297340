@import "../../assets/styles/variables";

.wrapper {
  padding: 25px;
  width: calc(100vw - 40px);
  max-width: 472px;
  padding-top: 55px;
}

.title {
  text-align: center;
}

.desc {
  margin-top: 20px;
  color: $primaryGrey;
  text-align: center;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 286px;
  margin-top: 40px;
}

.button {
  &_delete {
    background: #D80027;
    border: 2px solid #D80027;
    :global .round-button__label {
      color: #FFFFFF;
    }
  }

  &_confirm {
    border: 2px solid var(--Neutral-Colors-Black-400, #000);
    background: var(--Neutral-Colors-Black-400, #000);
    :global .round-button__label {
      color: #FFFFFF;
    }
  }

  &_cancel {
    background: #F3F2F4;
    border: 2px solid #F3F2F4;
    :global .round-button__label {
      color: $primaryGrey;
    }
  }
}