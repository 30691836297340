.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding: 24px;
  display: flex;
  z-index: 1;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.60);
  opacity: 0;
  transition: opacity 0.4s;
  animation: fade_in 0.7s forwards;

  @keyframes fade_in {
    100% {opacity: 1;}
  }
}

.content {
  padding: 0 24px;
  margin: auto;
  z-index: 1;
}