.block {
  display: flex;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 12px;
  border-radius: var(--M-radius, 6px);
  &:global.success {
    background: rgba(50, 186, 118, 0.15);
  }
  &:global.error {
    background: var(--System-Colors-Danger-Light, #FFD9E2);
  }
  &:global.info {
    background: var(--System-Colors-Info-Light, #E1EFFD);
  }
  &:global.warning {
    background: var(--System-Colors-Warning-Light, #FFEBCE);
  }
}

.icon {
  width: 18px;
  min-width: 18px;
}

.content {}

.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.desc {
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.70);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}