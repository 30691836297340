@import '../../../assets/styles/variables';

.fundraising_settings {
  &__notifications {
    margin-top: 20px;
  }
  &__participate {
    margin-top: 16px;
  }
  &__dashboard {
    margin-top: 40px;
  }
  &__co_founders {
    margin-top: 50px;
    border-bottom: 1px solid #9e98a325;
    &_desc {
      margin: 5px 0 15px;
    }
  }
  &__co_founder {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(270px, 1fr);
    align-items: center;
    grid-column-gap: 20px;
    padding: 15px 0;
    border-top: 1px solid #9e98a325;
    &_avatar {
      margin-right: 12px;
    }
    &_status {
      position: relative;
      color: $primaryGrey;
      padding-left: 24px;
      &.active {
        color: $primaryBlack;
        &::before {
          background: $primaryYellow;
          opacity: 1;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        background: $primaryGrey;
        opacity: 0.2;
        border-radius: 50%;
      }
    }
    &_content {
      overflow: hidden;
      & > div:last-child {
        color: $primaryGrey;
      }
    }
    &_left {
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.badge {
  padding: 4px 12px;
  border-radius: 6px;
  background: var(--Green-200, #5AC790);
  color: #FFF;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 8px;
}

.confirm_link {
  display: inline-block;
  border-radius: var(--M-radius, 6px);
  background: var(--Neutral-Colors-White, #FFF);
  color: var(--Neutral-Colors-Black-400, #000);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  padding: 5px 12px;
  margin-top: 8px;
}

.header {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
}

.reset_btn {
  border-radius: 90px;
  border: 1.5px solid #000;
  background: var(--Neutral-Colors-White, #FFF);
  padding: 5px 18px;
  min-height: 50px;
  color: var(--Neutral-Colors-Black-400, #000);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.messages {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
