.wrapper {}

.payment_link {
  margin-top: 15px;
}

.fpg {
  &__wrapper {
    margin-top: 24px;
  }
  &__options {
    display: grid;
    grid-gap: 20px;
    margin-top: 8px;
  }
  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    & > span {
      color: var(--Black, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__desc {
    margin-top: 8px;
  }

  &__option {
    border-radius: 12px;
    border: 2px dashed #D6D6D6;
    background: #F4F4F4;
    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    flex-grow: 1;
    &_selected {
      border: 2px solid #000;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }
    &_top {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &_title {
      color: var(--Color-2, #F17100);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &_label {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &_input {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    &_cost {
      border-radius: 12px;
      background: #EBEAEC;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      color: #000;
      font-style: normal;
      min-height: 56px;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.checkboxes {
  margin-top: 16px;
}

.buttons {
  margin-top: 16px;
}