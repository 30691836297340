.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding: 24px;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.60);
  opacity: 0;
  transition: opacity 0.4s;
  animation: fade_in 0.7s forwards;

  @keyframes fade_in {
    100% {opacity: 1;}
  }
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  height: 100vh;
  overflow: hidden;
}

.close_btn {
  border-radius: 12px;
  background: #FFF;
  width: 42px;
  height: 42px;
  min-width: 42px;
}