.wrapper {
  width: 100vw;
  max-width: 600px;
  border-radius: 12px;
  background: #FFF;
  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  color: var(--Black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.save_btn {
  border-radius: 90px;
  background: var(--Primary-Colors-Yellow-400, #FFD200);
  display: flex;
  height: 50px;
  padding: 4px 18px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
}