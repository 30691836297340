.radio_input {
  display: inline-block;
  height: 24px;
  width: 24px;
  min-width: 24px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio_input input {
  position: absolute;
  visibility: hidden;
  cursor: pointer;
}

.radio_input__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ebeaec;
  border-radius: 50%;
}

.radio_input:hover input ~ .radio_input__checkmark {
  background-color: #ccc;
}

.radio_input input:checked ~ .radio_input__checkmark {
  background-color: var(--Primary-Colors-Yellow-400, #FFD200);
}

.radio_input input:disabled ~ .radio_input__checkmark {
  background-color: #ccc;
}

.radio_input__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio_input input:checked ~ .radio_input__checkmark:after {
  display: block;
}

.radio_input .radio_input__checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
}
