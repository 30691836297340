@import '../../assets/styles/variables';

.loans-page {
  margin-top: 50px;
  margin-bottom: 100px;
  $gridTemplateColumn: minmax(120px, 120px) minmax(170px, 170px) minmax(170px, 170px) minmax(170px, 170px) minmax(170px, 170px);
  $gridTemplateColumnOld: minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px)
    minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px);
  $gridTemplateColumnNew: minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px)
    minmax(120px, 120px) minmax(120px, 120px);
  $gridTemplateColumnMixed: minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px)
    minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px);
  $gridGap: 30px;

  &__links {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    & .round-link:first-child {
      margin-right: 15px;
    }
  }
  &__empty {
    margin-top: 25px;
    color: $primaryGrey;
  }
  &__table {
    margin-top: 25px;
  }

  &__thead,
  &__tbody-row {
    display: grid;
    grid-column-gap: $gridGap;
    align-items: center;
    justify-content: space-between;
  }

  &__thead,
  &__tbody-row {
    grid-template-columns: $gridTemplateColumn;
  }
  &__thead.old,
  &__tbody-row.old {
    grid-template-columns: $gridTemplateColumnOld;
  }
  &__thead.new,
  &__tbody-row.new {
    grid-template-columns: $gridTemplateColumnNew;
  }
  &__thead.mixed,
  &__tbody-row.mixed {
    grid-template-columns: $gridTemplateColumnMixed;
  }

  &__thead {
    border-bottom: 1px solid #e6e5e7;
    &-col {
      padding: 20px 0;
      font-size: 14px;
      line-height: 20px;
      color: #9e98a3;
    }
  }

  &__tbody {
    &-row {
      border-bottom: 1px solid #e6e5e7;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    &-col {
      padding: 25px 0;
    }
  }
}
