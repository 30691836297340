.reset_btn {
  color: var(--Neutral-Colors-Black-400, #000);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  padding: 4px 18px;
  min-height: 50px;
  border-radius: 90px;
  border: 1.5px solid #000;
  background: var(--Neutral-Colors-White, #FFF);
}