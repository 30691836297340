.goal {
  display: flex;
  align-items: center;
  gap: 12px;
  &__wrapper {}
  &__value {
    color: var(--Black, #000);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__title {
    color: var(--Black, #000);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Poppins', 'sans-serif';
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__desc {
    color: #8D8D8D;
  }
}